import React, { useReducer, createContext, useEffect } from "react";
import axios from "axios";
import uniq from "lodash/uniq";

import ReactGA from "react-ga";
import { filterTypes } from "../utils/types";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// TYPES
const GET_SPECIALS_LOADING = "GET_SPECIALS_LOADING";
const GET_SPECIALS_SUCCESS = "GET_SPECIALS_SUCCESS";
const GET_SPECIALS_FAILED = "GET_SPECIALS_FAILED";

const GET_QUERY_VALUES = "GET_QUERY_VALUES";

const SET_FILTER_SELECTION = "SET_FILTER_SELECTION";
const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
const SET_SORT_SELECTION = "SET_SORT_SELECTION";

const CLEAR_DEALER_FILTER = "CLEAR_DEALER_FILTER";
const CLEAR_FILTER_SELECTION = "CLEAR_FILTER_SELECTION";
const SET_NO_MAKES = "SET_NO_MAKES";

const SHOW_MODAL = "SHOW_MODAL";
const HIDE_MODAL = "HIDE_MODAL";

const HANDLE_SEARCH_FILTER = "HANDLE_SEARCH_FILTER";

const UPDATE_ACTIVE_SPECIAL = "UPDATE_ACTIVE_SPECIAL";

const initialState = {
  staticSpecials: [],
  staticDealerships: [],
  filteredSpecials: [],
  filterSelections: {
    price: [],
    payment: [],
    body: [],
    year: [],
    make: [],
    model: [],
    location: [],
    sale_type: [],
    condition: [],
    dealer_id: [],
  },
  noMakes: false,
  allMakes: [],
  allModels: [],
  allBodyStyles: [],
  allYears: [],
  allLocations: [],
  getSpecialsLoading: false,
  getSpecialsFailed: {},
  modalShow: false,
  modalContent: "",
  currentPage: 1,
  perPage: 12,
  sortOptions: [
    {
      value: "created_desc",
      text: "Created Date (Old to New)",
    },
    {
      value: "price_asc",
      text: "Price (High to Low)",
    },
    {
      value: "price_desc",
      text: "Price (Low to High)",
    },
    {
      value: "make_desc",
      text: "Make (A-Z)",
    },
    {
      value: "make_asc",
      text: "Make (Z-A)",
    },
    {
      value: "year_asc",
      text: "Year (New to Old)",
    },
    {
      value: "year_desc",
      text: "Year (Old to New)",
    },
  ],
  sortValue: "created_desc",
  activeSpecial: null,
  currentSearch: "",
};

const filterBySearch = (search, specials) => {
  return search.reduce((acc, val) => {
    return acc.filter((special) => {
      return (
        special.vehicle.year.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
        special.vehicle.make.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
        special.vehicle.model.toLowerCase().indexOf(val.toLowerCase()) > -1
      );
    });
  }, specials);
};

const applyFilters = (search, filters, specials, dealerships, noMakes) => {
  if (search.length) {
    if (typeof search === "string") {
      search = search ? search.split(" ") : [];
    }
    specials = filterBySearch(search, specials);
  }

  if (filters.sale_type.length) {
    const sale_type = filters.sale_type.map((type) => {
      return type.toLowerCase();
    });
    specials = specials.filter(
      (sp) => sale_type.indexOf(sp.sale_type.toLowerCase()) > -1
    );
  }
  if (filters.make.length) {
    specials = specials.filter(
      (sp) => filters.make.indexOf(sp.vehicle.make) > -1
    );
  }
  if (filters.model.length) {
    specials = specials.filter(
      (sp) => filters.model.indexOf(sp.vehicle.model) > -1
    );
  }
  if (filters.body.length) {
    specials = specials.filter(
      (sp) => filters.body.indexOf(sp.vehicle.body) > -1
    );
  }
  if (filters.year.length) {
    specials = specials.filter(
      (sp) => filters.year.indexOf(sp.vehicle.year) > -1
    );
  }

  if (filters.condition.length) {
    specials = specials.filter((sp) => {
      const condition = sp.vehicle.new === true ? "new" : "used";
      return filters.condition.indexOf(condition) > -1;
    });
  }

  if (noMakes) {
    if (filters.make.length == 1 && (filters.make[0] == "Mercedes-Benz" || filters.make[0] == "Honda")) {
        specials = specials.filter((sp) => {
            return sp.vehicle.new;
        });
    }
  }

  if (filters.dealer_id.length) {
    specials = specials.filter((sp) => {
      const dealers = filters.dealer_id.map((dealerId) => {
        const found = dealerships.find((dealer) => dealerId === dealer.id);
        return {
          vauto_id: found.vauto_id,
          makes: JSON.parse(found.makes).map((make) => make.toLowerCase()),
        };
      });

      const uniqueIds = [...new Set(dealers.map((d) => d.vauto_id))];
      const uniqueMakes = [
        ...new Set([].concat(...dealers.map((d) => d.makes))),
      ];

      return (
        (uniqueIds.includes(sp.vehicle.dealer_id) &&
          sp.vehicle.new === false) ||
        (uniqueMakes.includes(sp.vehicle.make.toLowerCase()) &&
          sp.vehicle.new === true)
      );
    });
  }

  const priceHasValues = filters.price.some((val) => {
    return val !== "";
  });
  if (filters.price.length && priceHasValues) {
    specials = specials.filter((sp) => {
      const price = parseInt(sp.sale_price);
      const type = sp.sale_type;
      return (
        type === "buy" &&
        price >= (parseInt(filters.price[0]) || 0) &&
        price <= (parseInt(filters.price[1]) || Infinity)
      );
    });
  }
  const paymentHasValues = filters.payment.some((val) => {
    return val !== "";
  });
  if (filters.payment.length && paymentHasValues) {
    specials = specials.filter((sp) => {
      const payment = parseInt(sp.sale_payment);
      const type = sp.sale_type;
      return (
        (type === "lease" || type === "finance") &&
        payment >= (parseInt(filters.payment[0]) || 0) &&
        payment <= (parseInt(filters.payment[1]) || Infinity)
      );
    });
  }
  return specials;
};

const sortSpecials = (sortParams, specials) => {
  const [key, direction] = sortParams.split("_");
  const dir = direction === "asc" ? -1 : 1;

  return specials.sort((a, b) => {
    if (key === "created") {
      const dateA = new Date(a[key]);
      const dateB = new Date(b[key]);
      return dateA > dateB ? 1 * dir : dateB > dateA ? -1 * dir : 0;
    } else if (key === "price") {
      const priceA = Number(a["sale_price"]) || Number(a["sale_payment"]) || 0;
      const priceB = Number(b["sale_price"]) || Number(b["sale_payment"]) || 0;
      return priceA > priceB ? 1 * dir : priceB > priceA ? -1 * dir : 0;
    } else {
      return a["vehicle"][key] > b["vehicle"][key]
        ? 1 * dir
        : b["vehicle"][key] > a["vehicle"][key]
        ? -1 * dir
        : 0;
    }
  });
};

const formatDealerships = (array) => {
  return array.map((value) => {
    // Split by parantheses
    const [name, subname] = value.name.split(/[()]+/g);
    return {
      ...value,
      name,
      subname,
    };
  });
};

const formatLocations = (array, specials) => {
  return array.map((value) => ({
    id: value,
    name: specials.find((sp) => sp.vehicle.dealer_id === value).vehicle
      .dealer_name,
  }));
};

const MainReducer = (state, action) => {
  switch (action.type) {
    case SET_NO_MAKES:
      return {
        ...state,
        noMakes: true,
        filterSelections: {
          ...state.filterSelections,
          make: [action.payload],
        }
      };
    case GET_SPECIALS_LOADING:
      return {
        ...state,
        getSpecialsLoading: action.payload,
      };
    case GET_SPECIALS_SUCCESS:
      const specials = action.payload.specials;
      const dealerships = formatDealerships(action.payload.dealerships);
      return {
        ...state,
        getSpecialsLoading: false,
        getSpecialsFailed: {},
        staticSpecials: specials,
        staticDealerships: dealerships,
        filteredSpecials: applyFilters(
          state.currentSearch,
          state.filterSelections,
          specials,
          dealerships,
          state.noMakes
        ),
        allMakes: uniq(specials.map((sp) => sp.vehicle.make)).sort(),
        allYears: uniq(specials.map((sp) => sp.vehicle.year))
          .sort()
          .reverse(),
        allBodyStyles: uniq(specials.map((sp) => sp.vehicle.body)).sort(),
        allLocations: formatLocations(
          uniq(specials.map((sp) => sp.vehicle.dealer_id)),
          specials
        ),
      };
    case GET_SPECIALS_FAILED:
      return {
        ...state,
        getSpecialsLoading: false,
        getSpecialsFailed: action.payload,
      };
    case GET_QUERY_VALUES:
      return {
        ...state,
        filterSelections: action.payload,
      };
    case SET_FILTER_SELECTION:
      return {
        ...state,
        filterSelections: action.payload,
        filteredSpecials: applyFilters(
          state.currentSearch,
          action.payload,
          state.staticSpecials,
          state.staticDealerships,
          state.noMakes
        ),
        currentPage: 1,
      };
    case HANDLE_SEARCH_FILTER:
      return {
        ...state,
        currentSearch: action.search,
        filteredSpecials: applyFilters(
          action.payload,
          state.filterSelections,
          state.staticSpecials,
          state.staticDealerships,
          state.noMakes
        ),
        currentPage: 1,
      };
    case SHOW_MODAL:
      return {
        ...state,
        modalShow: action.payload,
        modalContent: action.content,
      };
    case HIDE_MODAL:
      return {
        ...state,
        modalShow: action.payload,
      };
    case CLEAR_DEALER_FILTER:
      const removeDealerId = { ...state.filterSelections, dealer_id: [] };
      return {
        ...state,
        filterSelections: removeDealerId,
        filteredSpecials: applyFilters(
          state.currentSearch,
          removeDealerId,
          state.staticSpecials,
          state.staticDealerships,
          state.noMakes
        ),
      };
    case CLEAR_FILTER_SELECTION:
      const emptyFilters = {
        price: [],
        payment: [],
        body: [],
        year: [],
        make: state.noMakes ? state.filterSelections.make : [],
        model: [],
        location: [],
        sale_type: [],
        condition: [],
        dealer_id: [],
      };
      return {
        ...state,
        filterSelections: emptyFilters,
        filteredSpecials: applyFilters(
          state.currentSearch,
          emptyFilters,
          state.staticSpecials,
          state.staticDealerships,
          state.noMakes
        ),
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_SORT_SELECTION:
      return {
        ...state,
        sortValue: action.payload,
        filteredSpecials: sortSpecials(action.payload, state.filteredSpecials),
      };
    case UPDATE_ACTIVE_SPECIAL:
      return {
        ...state,
        activeSpecial: action.payload,
      };
    default:
      return state;
  }
};

export const MainContext = createContext();

export const MainProvider = ({ children }) => {
  const [MainState, dispatch] = useReducer(MainReducer, initialState);

  useEffect(() => {
    updateUrl();
  }, [JSON.stringify(MainState.filterSelections)]);

  const setOnlyMakes = (val) => {
    dispatch({
      type: SET_NO_MAKES,
      payload: val,
    })
  };


  const getSpecials = (dealerId) => {
    dispatch({
      type: GET_SPECIALS_LOADING,
      payload: true,
    });
    axios
      .get(`${BASE_URL}/api/specials?${dealerId ? `dealerId=${dealerId}` : ""}`)
      .then((res) => {
        dispatch({
          type: GET_SPECIALS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_SPECIALS_FAILED,
          payload: err.response.data,
        });
      });
  };

  const getSpecialById = (id, subId) => {
    return MainState.staticSpecials.find(
      (sp) => sp.id === id && sp.subspecial_id === subId
    );
  };

  const getRelatedSpecials = (id) => {
    return MainState.staticSpecials.filter((sp) => sp.id === id);
  };

  const getQueries = (search) => {
    const urlQuery = new URLSearchParams(search);
    const updatedFilterSelections = MainState.filterSelections;

    if (urlQuery.toString()) {
      urlQuery.forEach((value, key) => {
        if (value && value.length && Object.values(filterTypes).includes(key)) {
          if (key === filterTypes.PRICE || key === filterTypes.PAYMENT) {
            if (value[2] === 0) {
              updatedFilterSelections[key] = [];
            } else {
              updatedFilterSelections[key] = value.split(",");
            }
          } else {
            updatedFilterSelections[key] = value.split(",");
          }
        } else {
          updatedFilterSelections[key] = [];
        }
      });
      dispatch({
        type: GET_QUERY_VALUES,
        payload: updatedFilterSelections,
      });
    }
  };

  const updateUrl = () => {
    const urlQuery = Object.entries(MainState.filterSelections).reduce(
      (acc, [key, value]) => {
        if (value && value.length && Object.values(filterTypes).includes(key)) {
          const hasValues = value.some((val) => {
            return val !== "";
          });
          if (
            key === filterTypes.PRICE ||
            (key === filterTypes.PAYMENT && hasValues)
          ) {
            return (acc += `${key}=${value.map((val) => val)}&`);
          } else {
            return (acc += `${key}=${value.map((val) => val)}&`);
          }
        } else {
          return acc;
        }
      },
      "?"
    );

    window.history.pushState(null, null, urlQuery);
  };

  const handleCheckboxSelect = (filter, value, state) => {
    gaEvent("filter", filter, value);

    const updatedFilterSelections = MainState.filterSelections;
    if (state === true) {
      updatedFilterSelections[filter].push(value);
    } else {
      updatedFilterSelections[filter] = updatedFilterSelections[filter].filter(
        (f) => f !== value
      );
    }

    dispatch({
      type: SET_FILTER_SELECTION,
      payload: updatedFilterSelections,
    });
  };

  const handleRadioSelect = (filter, value, state) => {
    const updatedFilterSelections = MainState.filterSelections;
    updatedFilterSelections[filter] = value;

    dispatch({
      type: SET_FILTER_SELECTION,
      payload: updatedFilterSelections,
    });
  };

  const handleRangeSelect = (filter, min, max) => {
    const updatedFilterSelections = MainState.filterSelections;
    gaEvent("filter", filter, `${min || ""},${max || ""}`);

    if (filter === filterTypes.PRICE) {
      updatedFilterSelections.payment = [];
    } else if (filter === filterTypes.PAYMENT) {
      updatedFilterSelections.price = [];
    }

    updatedFilterSelections[filter] = [min || "", max || ""];

    dispatch({
      type: SET_FILTER_SELECTION,
      payload: updatedFilterSelections,
    });
  };

  const handleSearchFilter = (value) => {
    const valueArr = value ? value.split(" ") : [];

    if (valueArr.length) {
      gaEvent("search", "search", valueArr.join());
      dispatch({
        type: HANDLE_SEARCH_FILTER,
        search: value,
        payload: valueArr,
      });
    } else {
      dispatch({
        type: HANDLE_SEARCH_FILTER,
        search: "",
        payload: [],
      });
    }
  };

  const triggerModal = (content) => {
    if (content) {
      dispatch({
        type: SHOW_MODAL,
        payload: true,
        content: content,
      });
    } else {
      dispatch({
        type: HIDE_MODAL,
        payload: false,
      });
    }
  };

  const handleRemoveDealerFilter = () => {
    dispatch({
      type: CLEAR_DEALER_FILTER,
    });
  };

  const clearFilters = () => {
    dispatch({
      type: CLEAR_FILTER_SELECTION,
    });
  };

  const handlePageChange = (page) => {
    dispatch({
      type: SET_CURRENT_PAGE,
      payload: page,
    });
  };

  const handleSortSelect = (sortParams) => {
    const [key, direction] = sortParams.split("_");
    gaEvent("sort", key, direction);

    dispatch({
      type: SET_SORT_SELECTION,
      payload: sortParams,
    });
  };

  const setActiveSpecial = (special) => {
    dispatch({
      type: UPDATE_ACTIVE_SPECIAL,
      payload: special,
    });
  };

  const gaPageview = (page) => {
    ReactGA.pageview(page);
  };

  const gaEvent = (category = "", action = "", label = "") => {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  };
  ReactGA.initialize("UA-46151534-14", {
    testMode:
      process.env.REACT_APP_BASE_URL !== "https://specials.westherr.com",
  });

  return (
    <MainContext.Provider
      value={{
        MainState,
        getSpecials,
        getQueries,
        handleCheckboxSelect,
        handleRadioSelect,
        handleSearchFilter,
        triggerModal,
        clearFilters,
        setOnlyMakes,
        handlePageChange,
        handleRangeSelect,
        getSpecialById,
        getRelatedSpecials,
        handleSortSelect,
        setActiveSpecial,
        gaPageview,
        gaEvent,
        handleRemoveDealerFilter,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};
