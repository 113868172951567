import React, { useContext } from "react";
import { MainContext } from "../../../Context/MainContext";
import { Row, Col, Button } from "react-bootstrap";
import SpecialBadge from "./SpecialBadge";
import UilShareAlt from "@iconscout/react-unicons/icons/uil-share-alt";

const SpecialHeading = ({ special, vehicle }) => {
  const { triggerModal, setActiveSpecial, gaEvent } = useContext(MainContext);

  const handleClick = () => {
    setActiveSpecial(special);
    gaEvent("share", "click", "openModal");
    triggerModal("share");
  };

  return (
    <div className="border-bottom pt-5 pb-2 px-4 mt-n4 position-relative z-1">
      <div className="mb-2">
        <Row>
          <Col>
            <div>
              <span
                className={`d-flex text-xl font-weight-semibold leading-tight ${
                  vehicle.make.toLowerCase() === "nissan"
                    ? "font-nissan-brand"
                    : vehicle.make.toLowerCase() === "infiniti"
                    ? "font-infiniti-brand"
                    : ""
                }`}
              >
                {vehicle.year} {vehicle.make} {vehicle.model} {vehicle.series}
              </span>
            </div>
            <div className="d-flex align-items-center">
              <span className="text-gray-700 text-sm">
                {vehicle.body}
                {vehicle.drivetrain && ` • ${vehicle.drivetrain}`}
              </span>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col className="d-flex align-items-center">
          <SpecialBadge
            condition={special.vehicle.new}
            type={special.sale_type}
          />
        </Col>
        <Col xs="auto" className="d-flex align-items-center">
          <Button
            variant="link-blue-500"
            className="text-decoration-none d-flex align-items-center p-1"
            onClick={() => handleClick()}
          >
            <div className="d-flex mr-1">
              <UilShareAlt size="18" color="currentColor" />
            </div>
            <span className="text-uppercase font-weight-bold text-sm tracking-wide">
              Share
            </span>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SpecialHeading;
