import React from "react";
import dayjs from "dayjs";

const DealTerms = ({ special }) => {

  const formatPercent = (value) => {
    const pct = value.substring(0, 3);
    // Adds a zero if the percent ends with a period
    return pct.endsWith(".") ? pct + 0 : pct;
  };
  const formatDate = (val) => {
    return dayjs(val).format("MM/DD/YYYY");
  };

  const msrpTerms = special.msrp
    ? `MSRP = $${Number(special.msrp).toLocaleString()}.`
    : "MSRP = $_____";

  const saleTerms = special.sale_term
    ? special.sale_type === "lease"
      ? ` ${special.sale_term} month lease.`
      : special.sale_type === "apr"
      ? ` Financed at ${formatPercent(special.sale_apr)}% for ${
          special.sale_term
        } months.`
      : special.sale_type === "finance"
      ? ` financed at ${special.sale_term} months on approved credit.`
      : ""
    : "";

  const paymentTerms = Number(special.sale_payment)
    ? ` Payment $${Number(special.sale_payment).toLocaleString()},`
    : "";

  const mpyTerms = Number(special.miles_per_year)
    ? ` ${Number(
        special.miles_per_year
      ).toLocaleString()} miles per year allowable,`
    : "";

  const cpmTerms = Number(special.cents_per_mile)
    ? ` $${(special.cents_per_mile / 100).toFixed(2)} per mile thereafter.`
    : "";

  const downPaymentTerms =
    special.down_payment && special.down_payment != "0"
      ? ` $${Number(special.down_payment).toLocaleString()} down payment.`
      : " No down payment required.";

  const firstPaymentTerms =
    special.plus_first_payment && special.plus_first_payment != "1"
      ? ` Plus first payment.`
      : " First payment included.";

  const salesTaxTerms =
    special.plus_sales_tax && special.plus_sales_tax != "1"
      ? ` Plus sales tax.`
      : " Sales tax included.";

  const regFeeTerms =
    special.plus_registration_fee && special.plus_registration_fee != "1"
      ? ` Plus registration fees.`
      : " Registration fees included.";

  const acqFeeTerms =
    special.plus_acquisition_fee && special.plus_acquisition_fee != "1"
      ? ` Plus acquisition fees.`
      : " Acquisition fees included.";

  const approvedCreditTerms =
    special.approved_credit && special.approved_credit != "0"
      ? ` Not all buyers will qualify.`
      : "";

  const lendingSourceTerms = special.lending_source
    ? ` On approved credit through ${special.lending_source}.`
    : "";

  const additionalTerms = special.additional_terms
    ? ` ${special.additional_terms}`
    : "";

  const deliveryBy = special.end_date
    ? ` Must take delivery by ${formatDate(special.end_date)}.`
    : "";

  const stockNumber = special.stock_number
    ? ` Stock #: ${special.stock_number}.`
    : "";

  const vin = special.vehicle.vin ? ` VIN: ${special.vehicle.vin}.` : "";

  const nissanInfiniti = () => {
    if (
      special.vehicle.make === "Nissan" ||
      special.vehicle.make === "INFINITI"
    ) {
      if (special.vehicle.new === true) {
        return " Two or more at each location available at this price.";
      }
    }
    return "";
  };

  const modelNum = () => {
    if (
      special.vehicle.make === "Nissan" ||
      special.vehicle.make === "INFINITI"
    ) {
      if (special.model_num) {
        return ` Model #: ${special.model_num}.`;
      }
    }
    return "";
  };

  const toyota = () => {
    let toyotaTerms = "";
    if (special.vehicle.make === "Toyota") {
      if (special.adjusted_cap_cost) {
        toyotaTerms += ` Adjusted Capitalized Cost: $${Number(
          special.adjusted_cap_cost
        ).toLocaleString()}.`;
      }
      if (special.dealer_doc_fee) {
        toyotaTerms += ` Dealer Doc Fee: $${Number(
          special.dealer_doc_fee
        ).toLocaleString()}.`;
      }
    }
    return toyotaTerms;
  };

  let termsValue = "";
  switch (special.sale_type) {
    case "apr":
      termsValue +=
        lendingSourceTerms +
        saleTerms +
        " Security deposit waived." +
        approvedCreditTerms +
        " Down payment may be required. See dealer for details." +
        ` Offer ends ${
          special.end_date ? formatDate(special.end_date) : "_____"
        }.` +
        firstPaymentTerms +
        salesTaxTerms +
        regFeeTerms +
        stockNumber +
        modelNum() +
        nissanInfiniti();
      break;
    case "finance":
      termsValue +=
        msrpTerms +
        (special.vehicle.new ? "" : ` Sale price = $${special.sale_price}.`) + // Used Vehicle
        paymentTerms +
        saleTerms +
        approvedCreditTerms +
        deliveryBy +
        firstPaymentTerms +
        salesTaxTerms +
        regFeeTerms +
        " Prior sales excluded." +
        toyota() +
        stockNumber +
        modelNum() +
        nissanInfiniti();
      break;
    case "lease":
      termsValue +=
        msrpTerms +
        paymentTerms +
        saleTerms +
        mpyTerms +
        cpmTerms +
        downPaymentTerms +
        firstPaymentTerms +
        salesTaxTerms +
        regFeeTerms +
        acqFeeTerms +
        " Security deposit waived." +
        lendingSourceTerms +
        deliveryBy +
        " Prior sales excluded." +
        toyota() +
        stockNumber +
        vin +
        modelNum() +
        nissanInfiniti();
      break;
    case "buy":
      termsValue +=
        msrpTerms +
        salesTaxTerms +
        regFeeTerms +
        deliveryBy +
        " Prior sales excluded." +
        " All applicable offers applied." +
        toyota() +
        stockNumber +
        modelNum() +
        nissanInfiniti();
      break;
  }

  termsValue += additionalTerms;

  return (
    <>
      <p>{termsValue}</p>
      <p className="mb-0">
        While we make every reasonable effort to provide accurate information on
        this site, we are not responsible for any errors or omissions on this
        site and the actual description and condition of the vehicle must be
        verified by you at the time of purchase. All prices do not include
        applicable taxes, including sales, use and tire taxes, finance charges,
        title, registration and dealer document/service fees. Vehicle
        availability, pricing, and description are subject to change without
        prior notice. All finance and lease offers are on approved credit. See
        dealer for complete details.
      </p>
    </>
  );
};

export default DealTerms;
